import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"
import { autoGravity } from "@cloudinary/url-gen/qualifiers/gravity"
import type { Asset, CloudinaryAsset, S3Asset } from "types"
import { getAssetType } from "./getAssetType"
import { getLegacyAssetPath } from "./getLegacyAssetPath"

interface AssetSize {
    width: number
    height: number
}

const cloudName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME || ""
const cloudinaryClient = new Cloudinary({
    cloud: {
        cloudName
    }
})

const getAssetUrl = (asset: Asset | CloudinaryAsset | S3Asset, size?: AssetSize): string => {
    if (asset) {
        const assetType = getAssetType(asset)

        if (assetType === "cloudinary") {
            const id = (asset as CloudinaryAsset).cloudinaryId
            const cloudImage = cloudinaryClient.image(id)
            cloudImage.resize(
                fill()
                    .width(size?.width || 128)
                    .height(size?.height || 128)
                    .gravity(autoGravity())
            )
            return cloudImage.toURL()
        } else return getLegacyAssetPath(asset) || ""
    }

    return ""
}

export default getAssetUrl
